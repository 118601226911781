const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');

const ICON_ID = 'share';
const namespace = 'ui-pdp-icon ui-pdp-icon--share';

const IconShare = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconShare.propTypes = {
  className: string.isRequired,
};

module.exports = React.memo(IconShare);
exports = module.exports;
exports.ICON_ID = ICON_ID;
